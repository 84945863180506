import { combineReducers } from 'redux';
import LoaderReducer from './Loader/LoaderReducer';
import DocumentLoadReducer from './Loader/DocumentLoadReducer';
import UserDetailsReducer from './User/UserDetails';
import CartDetailsReducer from './User/CartDetails';
import OrderDetailsReducer from './User/OrderDetails';

export default combineReducers({
    loaderVisible: LoaderReducer,
    documentLoad: DocumentLoadReducer,
    UserDetailsReducer,
    CartDetailsReducer,
    OrderDetailsReducer
});
