import Cookies from 'universal-cookie';
import { get, post } from '../../../utils/axios';
import AlertMsg from '../../../components/common/AlertMsg';
import { CART_DETAILS_SUCCESS, REMOVE_ITEM_SUCCESS, ORDER_DETAILS_SUCCESS } from '../../../constants/constants';


const cookies = new Cookies();

export function CartDetailsAction() {
    return async dispatch => {
        try {
            const token = cookies.get('token');

            if (token) {
                get(`/cart`)
                    .then(res => {
                        if (res.success) {
                            dispatch(GetCartDetailsSuccess(res.data))
                        } else {
                            cookies.remove('token');
                            dispatch(GetCartDetailsSuccess(null))
                        }

                    })
            } else {
                cookies.remove('token');
                let getCartData = JSON.parse(localStorage.getItem('cartData'));
                dispatch(GetCartDetailsSuccess(getCartData))
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function AddToCart(product_id, product_type, isLoggedIn = false) {

    const removeFromLocalStorage = () => {
        let cartData = JSON.parse(localStorage.getItem('cartData'));

        if (cartData[product_type] && cartData[product_type].length > 0 && cartData[product_type].some(data => data.productId._id === product_id)) {

            const filteredCartPerProduct = cartData[product_type].filter(data => data.productId._id !== product_id);

            cartData[product_type] = filteredCartPerProduct;

            localStorage.setItem('cartData', JSON.stringify(cartData));
        }
    }

    return async dispatch => {
        try {
            const token = cookies.get('token');

            if (token) {
                post(`/cart/addtoCart`, {
                    "productId": product_id,
                    "type": product_type
                })
                    .then((res) => {
                        if (res.success) {
                            AlertMsg('success', res.message);
                            if (isLoggedIn) {
                                removeFromLocalStorage();
                            }
                            dispatch(GetCartDetailsSuccess(res.data));
                        } else {
                            AlertMsg('error', res.message);
                            removeFromLocalStorage();
                        }
                    })
            } else {
                window.location.href = '/login';
            }

        } catch (error) {
            console.log(error);
        }
    }
}

export function RemoveItem(product_id, product_type) {

    return async dispatch => {
        try {
            const token = cookies.get('token');

            if (token) {
                post(`/cart/emptyCart`, {
                    "productId": product_id,
                    "type": product_type
                })
                    .then((res) => {
                        if (res.success) {
                            AlertMsg('success', res.message);
                            dispatch(RemoveItemSuccess({ "productId": product_id, "type": product_type }));
                        } else {
                            AlertMsg('error', res.message);
                        }
                    })
            } else {

                let cartData = JSON.parse(localStorage.getItem('cartData'));

                if (cartData[product_type] && cartData[product_type].length > 0 && cartData[product_type].some(data => data.productId._id === product_id)) {

                    const filteredCartPerProduct = cartData[product_type].filter(data => data.productId._id !== product_id);

                    cartData[product_type] = filteredCartPerProduct;

                    localStorage.setItem('cartData', JSON.stringify(cartData));

                    dispatch(GetCartDetailsSuccess(cartData));

                    AlertMsg('success', 'Product removed from cart')
                }

                // window.location.href = '/login';
            }

        } catch (error) {
            console.log(error);
        }
    }
}


export function GetCartDetailsSuccess(data) {
    return {
        type: CART_DETAILS_SUCCESS,
        payload: data
    };
}

export function RemoveItemSuccess(data) {
    return {
        type: REMOVE_ITEM_SUCCESS,
        payload: data
    };
}

export function GetOrderDetailsSuccess(data) {
    return {
        type: ORDER_DETAILS_SUCCESS,
        payload: data
    };
}
