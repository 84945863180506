import { USER_DETAILS_SUCCESS } from '../../../constants/constants';

const GetUserDetailsSuccess = (state = {}, action) => {
    switch (action.type) {
        case USER_DETAILS_SUCCESS:
            return Object.assign({}, action.payload);
        default:
            return state;
    }
};

export default GetUserDetailsSuccess;