import { ORDER_DETAILS_SUCCESS } from '../../../constants/constants';
import _ from 'lodash';

const GetOrderDetailsSuccess = (state = {}, action) => {
      switch (action.type) {
            case ORDER_DETAILS_SUCCESS:
                  return Object.assign({}, action.payload);
            default:
                  return state;
      }
};

export default GetOrderDetailsSuccess;