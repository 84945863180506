module.exports = {
  GET_LOADER: 'GET_LOADER',
  REMOVE_LOADER: 'REMOVE_LOADER',
  RESET_LOADER: 'RESET_LOADER',
  DOCUMENT_LOAD: 'DOCUMENT_LOAD',
  USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',

  CART_DETAILS_SUCCESS: "CART_DETAILS_SUCCESS",
  ORDER_DETAILS_SUCCESS: 'ORDER_DETAILS_SUCCESS',
  REMOVE_ITEM_SUCCESS: "REMOVE_ITEM_SUCCESS",

  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAIL: "REGISTER_FAIL",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGOUT: "LOGOUT",
};
