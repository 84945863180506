import React, { useState, useEffect } from "react";
import { StrictMode } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { UserDetailsAction } from '../../modules/actions/User/getUserDetails';
import { CartDetailsAction } from '../../modules/actions/User/getCartDetails';
import { isMobile } from 'react-device-detect';

import Cookies from 'universal-cookie';
import { get } from "../../utils/axios";
import Swal from "sweetalert2";

const cookies = new Cookies();


function Header() {

    const location = useLocation();

    let [board, setBoard] = useState([]);
    let [practiceCategory, setPracticeCategory] = useState([]);
    let [pastCategory, setPastCategory] = useState([]);
    let [packages, setPackages] = useState([]);
    let [navbar, setNavbar] = useState({});


    const user = useSelector((state) => state.UserDetailsReducer);
    const cart = useSelector((state) => state.CartDetailsReducer);

    let count = 0;
    if (cart && cart.Package && cart.Package.length > 0) {
        count = count + cart.Package.length;
    }
    if (cart && cart.ExamPaper && cart.ExamPaper.length > 0) {
        count = count + cart.ExamPaper.length;
    }
    if (cart && cart.PracticePaper && cart.PracticePaper.length > 0) {
        count = count + cart.PracticePaper.length;
    }
    if (cart && cart.TutionPaper && cart.TutionPaper.length > 0) {
        count = count + cart.TutionPaper.length;
    }
    if (cart && cart.mockExamsOffline && cart.mockExamsOffline.length > 0) {
        count = count + cart.mockExamsOffline.length;
    }
    if (cart && cart.mockExamsOnline && cart.mockExamsOnline.length > 0) {
        count = count + cart.mockExamsOnline.length;
    }

    const username = ('Hi, ' + (user && user.firstName && user.firstName.split(' ')[0].charAt(0).toUpperCase() + user.firstName.split(' ')[0].slice(1).toLowerCase()));

    const dispatch = useDispatch();

    const logout = () => {
        cookies.remove('token');
        cookies.remove('userId');
        localStorage.removeItem('cartData');
        dispatch(UserDetailsAction());
        dispatch(CartDetailsAction());
        window.location.href = '/home';
    }

    const getNavbar = () => {
        get(`/mockexam-subcategory/navbar`)
            .then((res) => {
                if (res.success) {
                    setNavbar(res.data);
                }
            })
    };

    const getBoardData = () => {
        get(`/board`)
            .then((res) => {
                if (res.success) {
                    setBoard(res.data);
                }
            })
    };

    const getPracticeCategory = () => {
        get(`/practicepaper-category`)
            .then((res) => {
                if (res.success) {
                    setPracticeCategory(res.data);
                }
            })
    };

    const getPastCategory = () => {
        get(`/exam-category`)
            .then((res) => {
                if (res.success) {
                    setPastCategory(res.data);
                }
            })
    };

    const getPackages = () => {
        get(`/packages`)
            .then((res) => {
                if (res.success) {
                    setPackages(res.data);
                }
            })
    };


    const showIncomingAlert = () => {
        Swal.fire(
            {
                title: 'Launching Soon. Stay tuned!!',
                icon: 'info'
            }
        )
    }

    useEffect(() => {
        getNavbar();
        getBoardData();
        getPracticeCategory();
        getPastCategory();
        getPackages();
        window.scrollTo(0, 0);
    }, [location]);

    const hideHamburgerMenu = (isMobileView) => {
        if (isMobileView) {
            document.getElementsByClassName('innernavdiv wow fadeInUp')[0].style.cssText = 'visibility: visible; animation-name: fadeInUp; left: -300px;'
            document.getElementsByClassName('hamburgur')[0].children[0].src = '/images/menuIcon.png';
            document.getElementsByClassName('hamburgur')[0].classList.remove("active");
            document.getElementsByClassName('innerPagesMocks hideScore')[0].classList.remove("hideScore");
        }
    }

    return (
        <StrictMode>
            <header id="header" className={`wow fadeInUp innerHeader ${user && user._id ? 'userdashboardheader' : ''}`} style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                <div className="container">
                    <div className="headerWrapper">
                        <Link className="linkMenu" to='/'>
                            <div className="pluslogocenter">
                                <img src="/images/11plus-logo.png" alt="" width="304" />
                            </div>
                        </Link>


                        <div className="menuWraper">
                        </div>

                        <div className="shoppingcartwrap">
                            <Link className="linkMenu" to='/cart'>
                                <img src="/images/shopping-cart.svg" alt="" width="28" height="26" />
                            </Link>
                            <span style={{ position: 'absolute', paddingLeft: '5px' }}>{count ? count : ''}</span>
                        </div>

                        <div className="signinbtnwrap">
                            {
                                !user._id ?
                                    <Link className="linkMenu maincta" to={location.pathname.includes('login') ? '/register' : '/login'}
                                        state={{ referer: location.pathname.substring(1) }}>
                                        <span> {location.pathname.includes('login') ? 'Sign Up' : 'Sign In'} </span>
                                        <img src="/images/arrow.svg" alt="" />
                                    </Link>
                                    :

                                    <ul id="mainMenu" style={{ display: "block" }} className="dashboardMenu">
                                        <li className="hasSubmenu" >

                                            <a className="linkMenu userIocntop" href>
                                                <div>
                                                    <div>{username}</div>
                                                    <img
                                                        src={(user.profileImage && user.profileImage.trim().length > 0)
                                                            ? user.profileImage
                                                            : process.env.PUBLIC_URL + '/images/userImage.jpg'}
                                                        alt="" />
                                                </div>

                                            </a>
                                            <ul className="submenu">
                                                <li>
                                                    <Link className="linkMenu" to="/dashboard" >Dashboard</Link>
                                                </li>
                                                <li>
                                                    <Link className="linkMenu" to="/my-purchase" >My purchases</Link>
                                                </li>
                                                <li onClick={logout}>
                                                    <Link className="linkMenu" to="#" > Logout</Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>


                            }
                        </div>

                    </div>
                </div>

                <div className="notify-section">
                    <div className="notify-sucess hide">testing 1</div>
                    <div className="notify-error hide">testing 2</div>
                </div>

                <section className="innernavdiv wow fadeInUp">
                    <div className="container">
                        <ul>
                            <li >
                                <NavLink className="nav-link" to="/home"  > Home</NavLink>
                            </li>
                            <li className="hasSubmenu">
                                <a href>Services</a>
                                <ul className="submenu">
                                    <li>
                                        <Link className="linkMenu" to="/mock-exam" state={{ selected: 'all', status: 'online' }} >
                                            Mock Exams
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="linkMenu" to="/practice-paper" >
                                            Practice Papers
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="linkMenu" to="/past-paper" >
                                            Past Papers
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="linkMenu" to="/product" >
                                            Featured Products
                                        </Link>
                                    </li>
                                    <li>
                                        {/* <Link className="linkMenu" to="/tuitions" >
                                            Tuitions
                                        </Link> */}
                                        <a onClick={showIncomingAlert} className="linkMenu" href  >
                                            Tuition
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <li className="hasSubmenu">
                                {isMobile ? <a href>
                                    Mock Exams
                                </a> : <Link className="" to="/mock-exam" >
                                    Mock Exams
                                </Link>}

                                {board && board.length > 0 && <ul className="submenu">
                                    {board && board.length > 0 && board.map((item, index) => {
                                        return (
                                            <li className="hasSubmenu2">
                                                <Link className="linkMenu" to="/board-details" state={{ data:item }}>

                                                    {item.name}

                                                    {navbar && navbar[item._id] && navbar[item._id].length > 0 && <ul className="subMenu2">
                                                        {navbar && navbar[item._id] && navbar[item._id].length && navbar[item._id].map((cat, index) => {
                                                            return (index < 5 &&
                                                                <li onClick={() => hideHamburgerMenu(isMobile)} key={index} >
                                                                    <Link className="linkMenu" to={`/product-details`} state={{ product: cat }}>
                                                                        {cat.name}
                                                                    </Link>
                                                                </li>)
                                                        })}

                                                        {navbar && navbar[item._id] && navbar[item._id].length > 5 && <li onClick={() => hideHamburgerMenu(isMobile)} key={2}>
                                                            <Link className="linkMenu" to="/book-exam-online" state={{ selected: item._id, status: 'online' }}>
                                                                View more
                                                            </Link>
                                                        </li>}
                                                    </ul>}

                                                </Link>
                                            </li>

                                        )
                                    })}
                                </ul>}
                            </li>

                            <li className="hasSubmenu">
                                {isMobile ? <a href>
                                    Practice Papers
                                </a> : <Link className="" to="/practice-paper" >
                                    Practice Papers
                                </Link>
                                }
                                {practiceCategory && practiceCategory.length > 0 && <ul className="submenu">
                                    {practiceCategory && practiceCategory.length > 0 && practiceCategory.map((item, index) => {
                                        return (index < 5 &&
                                            <li onClick={() => hideHamburgerMenu(isMobile)} key={item._id}>
                                                <Link className="linkMenu" to="/product-details" state={{ product: item }}>
                                                    {item.name}
                                                </Link>
                                            </li>
                                        )
                                    })}
                                    {practiceCategory && practiceCategory.length > 5 &&
                                        <li onClick={() => hideHamburgerMenu(isMobile)} key={2}>
                                            <Link className="linkMenu" to="/practice-paper">
                                                View more
                                            </Link>
                                        </li>}
                                </ul>}
                            </li>
                            <li className="hasSubmenu">
                                {isMobile ? <a href >
                                    Past Papers
                                </a> : <Link className="" to="/past-paper" >
                                    Past Papers
                                </Link>}

                                {pastCategory && pastCategory.length > 0 && <ul className="submenu">
                                    {pastCategory && pastCategory.length > 0 && pastCategory.map((item, index) => {
                                        return (index < 5 &&
                                            <li onClick={() => hideHamburgerMenu(isMobile)} key={item._id}>
                                                <Link className="linkMenu" state={{ product: item }} to={`/product-details`}>
                                                    {item.name}
                                                </Link>
                                            </li>
                                        )
                                    })}
                                    {pastCategory && pastCategory.length > 5 &&
                                        <li onClick={() => hideHamburgerMenu(isMobile)} key={2}>
                                            <Link className="linkMenu" to="/past-paper" >
                                                View more
                                            </Link>
                                        </li>}
                                </ul>}
                            </li>
                            <li className="hasSubmenu">
                                {isMobile ? <a href >
                                    Featured Products
                                </a> : <Link className="" to="/product" >
                                    Featured Products
                                </Link>}

                                {packages && packages.length > 0 && <ul className="submenu">
                                    {packages && packages.length > 0 && packages.map((item, index) => {
                                        return (index < 5 &&
                                            <li onClick={() => hideHamburgerMenu(isMobile)} key={item._id}>
                                                <Link className="linkMenu" state={{ product: item }} to={`/product-details`} >
                                                    {item.name}
                                                </Link>
                                            </li>
                                        )
                                    })}
                                    {packages && packages.length > 5 &&
                                        <li onClick={() => hideHamburgerMenu(isMobile)} key={2}>
                                            <Link className="linkMenu" to="/product">
                                                View more
                                            </Link>
                                        </li>}
                                </ul>}
                            </li>

                            {!!user && !!user.firstName && <li><Link to="/dashboard" state={{ status: 'online' }}>Dashboard </Link></li>}
                        </ul>
                    </div>
                </section>

            </header>
        </StrictMode>
    )
}

export default Header;