import { USER_DETAILS_SUCCESS } from '../../../constants/constants';
import { get } from '../../../utils/axios';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function UserDetailsAction() {
    return async dispatch => {
        try {
            const token = cookies.get('token');

            if (token) {
                get(`/user/profile`)
                    .then(res => {
                        if (res.success) {
                            dispatch(GetUserDetailsSuccess(res.data));
                            cookies.set('userId', res.data._id);
                        } else {
                            cookies.remove('token');
                            cookies.remove('userId');
                            dispatch(GetUserDetailsSuccess(null))
                        }

                    })
            } else {
                cookies.remove('token');
                cookies.remove('userId');
                dispatch(GetUserDetailsSuccess(null))
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function GetUserDetailsSuccess(data) {
    return {
        type: USER_DETAILS_SUCCESS,
        payload: data
    };
}