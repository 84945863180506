import { CART_DETAILS_SUCCESS, REMOVE_ITEM_SUCCESS } from '../../../constants/constants';
import _ from 'lodash';

const GetCartDetailsSuccess = (state = {}, action) => {
    switch (action.type) {
        case CART_DETAILS_SUCCESS:
            return Object.assign({}, action.payload);
        case REMOVE_ITEM_SUCCESS:
            let dataToUpdate = state;
            dataToUpdate[action.payload.type] = _.filter(dataToUpdate[action.payload.type], function(item) {
                  return (item.productId._id!==action.payload.productId);
            });

            let total = 0;
            if (dataToUpdate && dataToUpdate.Package && dataToUpdate.Package.length >0 ){
                  total = total + dataToUpdate.Package.length;
            }
            if (dataToUpdate && dataToUpdate.ExamPaper && dataToUpdate.ExamPaper.length >0 ){
                  total = total + dataToUpdate.ExamPaper.length;
            }
            if (dataToUpdate && dataToUpdate.PracticePaper && dataToUpdate.PracticePaper.length >0 ){
                  total = total + dataToUpdate.PracticePaper.length;
            }
            if (dataToUpdate && dataToUpdate.TutionPaper && dataToUpdate.TutionPaper.length >0 ){
                  total = total + dataToUpdate.TutionPaper.length;
            }
            if (dataToUpdate && dataToUpdate.mockExamsOffline && dataToUpdate.mockExamsOffline.length >0 ){
                  total = total + dataToUpdate.mockExamsOffline.length;
            }
            if (dataToUpdate && dataToUpdate.mockExamsOnline && dataToUpdate.mockExamsOnline.length >0 ){
                  total = total + dataToUpdate.mockExamsOnline.length;
            }
            
            if (total===0) { window.location.href = '/home'; }

            return Object.assign({}, dataToUpdate);    
        default:
            return state;
    }
};

export default GetCartDetailsSuccess;