import React from 'react';
import ReactDOM from 'react-dom';

export default function AlertMsg(type, message) {
    try {
        ReactDOM.render(<p className={type}>{message}</p>, document.getElementById('alertid'));
        setTimeout(() => {
            ReactDOM.render(<></>, document.getElementById('alertid'))
        }, 3000);
    } catch (error) {
        console.log(error);
    }
}
